import React, { useEffect } from 'react'
import Select from '../../../../../Components/Select/Select'
import DescriptionList from '../../../../../Components/DescriptionList/DescriptionList'
import InputSwitch from '../../../../../Components/Form/InputSwitch'
import { OPTIONS_LIGNE, SUBMIT_BON_COMMANDE } from '../../../queries'
import { useLazyQuery, useMutation } from '@apollo/client'
import Spinner from '../../../../../Components/Spinner'
import { useP } from '../../../../../services/i18n'
import { toastError } from '../../../../../Components/Utils/Utils'
import * as Sentry from "@sentry/react";
import TooltipInformation from '../../../../../Components/Tooltip/TooltipInformation'
import Badge from '../../../../../Components/Badge'

type GSMOptionsType = {
  livraisonId: string,
  prestationId: string,
}

export default function GSMOptions({ livraisonId, prestationId }: GSMOptionsType) {

  const p = useP();

  const [getOptionsLigne, { loading: loadingOptionsLigne, data: dataOptionsLigne }] = useLazyQuery(OPTIONS_LIGNE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      livraisonId: parseInt(livraisonId),
      filtersPrestation:[{
        column: "id",
        value: prestationId
      }],
    }
  });

  const optionsRender = (options, disabled: boolean) => {
    return options.map((o) => {

      let optionValue: JSX.Element;
      if (o.type === "BOOLEAN") {

        optionValue = <div>
          <InputSwitch
            labelPosition="before"
            name=''
            label=''
            value={o.value === "1"}
            disabled={o.readonly === "true" || disabled}
            onChange={(val) => onSaveOption(o.key, val)}            
          />
        </div>

      } else {

        const listdatas = o?.data?.map((d) => ({
          code: d.value,
          libelle: d.label
        }))

        optionValue = <div>
          <Select
            size='sm'
            label=""
            inlineLabel={true}
            disabled={o.readonly === "true" || disabled}
            defaultValue={listdatas.find((opt) => opt.code === o.value)}
            selectDatas={listdatas}
            onSelect={(val) => onSaveOption(o.key, val.code)}
          />
        </div>
      }

      return {
        label:
          <div className='flex items-center space-x-2'>
            <div>{o.label}</div>
            {o.information && <div><TooltipInformation contenu={<div className='max-w-[350px]'>{o.information}</div>} /></div>}
          </div>,
        value: o.message || optionValue
      }
    })
  }

  const onSaveOption = (optionKey, optionValue) => {
    saveOptionLigne({
      variables: {
        bonCommandeInput: {
          livraisonId: parseInt(livraisonId),
          action: "MODIFIER",
          values: [{
            key: optionKey,
            value: optionValue.toString()
          }],
          prestationId: parseInt(prestationId),
        }
      }
    }).then(({ data }) => {
      if (data.submitBonCommande.success) {
        getOptionsLigne()
      } else {

        const codeErreur = data?.submitBonCommande?.codeErreur;
        const tradErreur = `prestations.gsm.errors.${codeErreur}`
        const erreurMessage = p.has(tradErreur) ? p.t(tradErreur) : `${p.t('error.defaultMessage')} (Err: ${codeErreur})`

        console.error(data.submitBonCommande.message)
        Sentry.captureException(data.submitBonCommande.message);
        toastError(erreurMessage);
      }
    })
      .catch((error) => {
        Sentry.captureException(error);
        toastError(p.t('error.defaultMessage'));
      });
  }

  const [saveOptionLigne, { loading: loadingSaveOptions }] =
    useMutation(SUBMIT_BON_COMMANDE);

  useEffect(() => {
    getOptionsLigne()
  }, [])

  return <>
    <div className='lg:w-9/12 rounded-md border border-gray-200'>
      <h2 className='text-primary-700 font-medium text-md  px-4 pt-4'>{p.t('prestations.detail.options')}</h2>
      <div className='h-1 w-[100px] bg-secondary-200 my-2 ml-4 rounded-full'></div>
      <div className=''>
        <div className='mx-4'>
          {loadingOptionsLigne || loadingSaveOptions ? <Spinner /> : (        
            <>
              {dataOptionsLigne?.prestations?.items[0]?.bdcEnCours && 
                <Badge itemLibelle={p.t('prestations.gsm.actions.bdcEnCours')} itemColor="red" className='mb-2'/>}
              <DescriptionList datas={optionsRender(dataOptionsLigne?.optionsLigne ? dataOptionsLigne.optionsLigne : [], dataOptionsLigne?.prestations?.items[0]?.bdcEnCours)} />
            </>
            )
          }
        </div>
      </div>
    </div>
  </>
}


