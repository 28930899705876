import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/client";
import { BALANCE_INFOS } from "../../../queries";
import Constants from "../../../../../commons/Constants";
import Spinner from "../../../../../Components/Spinner";
import Select from "../../../../../Components/Select/Select";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useP } from "../../../../../services/i18n";
import clsx from "clsx";

const {
  GSM_FOURNISSEUR_EIT
} = Constants;

type ActionInformationsBalanceDataType = {
  prestationAffiche: any,
  onSaveCompleted: (any: any) => void,
}

export default function ActionInformationsBalanceData({ prestationAffiche, onSaveCompleted }: ActionInformationsBalanceDataType) {

  const p = useP();

  const { loading, data } = useQuery(BALANCE_INFOS, {
    variables: {
      livraisonId: parseInt(prestationAffiche?.abonnementLivraisonId),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  
  const onSaveLigne = async (result) => {
    await onSaveCompleted(result);
    return true;
  }

  const selectRecharge = [{ code: 0, libelle: 'Désactivé' }, { code: 80, libelle: `Dès 80% de l'enveloppe DATA` }, { code: 100, libelle: `A 100% de l'enveloppe DATA` }]
  const informationsBalances = data?.informationsBalances || [];
  const selectDatas = informationsBalances.map((e) => { return { code: e.value, libelle: e.label } });  
  
  const prixEIT = data?.informationsBalances?.find(
    (i) => i.label === "prixVente"
  );

  const saveField = async () => {   
    setLoadingSave(true) 
    await onSaveLigne({ 
      rechargeAuto,
      rechargeAutoOption : rechargeAutoOption || null 
    });    

    setRechargeAutoPrev(rechargeAuto)
    setRechargeAutoOptionPrev(rechargeAutoOption)

    setLoadingSave(false)
    setCanSave(false)    
  }

  const onCancel = () => {    
    setRechargeAuto(rechargeAutoPrev)
    setRechargeAutoOption(rechargeAutoOptionPrev)
    setCanSave(false)
  }

  const onChangeValue = () => {    
    setCanSave(true)
  }

  const [canSave, setCanSave]= useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const [rechargeAuto, setRechargeAuto] = useState(null);
  const [rechargeAutoOption, setRechargeAutoOption] = useState(null);

  const [rechargeAutoPrev, setRechargeAutoPrev] = useState(null);
  const [rechargeAutoOptionPrev, setRechargeAutoOptionPrev] = useState(null);

  useEffect(()=>{
    //Récupération des informations pour les recharge balances
    const ligne = prestationAffiche?.ligne;
    setRechargeAuto(ligne?.rechargeAuto)
    setRechargeAutoOption(ligne?.rechargeAutoOption)
    setRechargeAutoPrev(ligne?.rechargeAuto)
    setRechargeAutoOptionPrev(ligne?.rechargeAutoOption)
  }, [prestationAffiche])

  return <>
    <div className="flex items-center space-x-2">
      <div className="w-full">
        {loading ? <Spinner /> :
          <>
            <div className="flex">
              <div className="flex-1 flex flex-col space-y-1">                
                <Select
                  disabled={loadingSave}
                  size='sm'
                  label=""
                  inlineLabel={true}
                  value={selectRecharge.find((opt) => opt.code === rechargeAuto)}
                  selectDatas={selectRecharge}
                  onSelect={(val) => {
                    onChangeValue()
                    setRechargeAuto(val.code)
                    if (val.code === 0) {
                      setRechargeAutoOption(null)
                    }else{
                      setRechargeAutoOption(selectDatas[0].code)
                    }
                  }}
                />
                {prestationAffiche?.ligne?.gsmSim?.fournisseurId === GSM_FOURNISSEUR_EIT ?
                  <div className="relative w-full  pl-2 text-left text-sm pt-1">Prix de la recharge : <span className="font-semibold italic">{prixEIT?.value?.length ? prixEIT.value : 'Non défini'}</span></div>
                  : <div>
                    <Select
                      disabled={loadingSave}
                      size='sm'
                      label=""
                      inlineLabel={true}
                      value={rechargeAuto===0 ? { code: null, libelle: '---' } : selectDatas.find((opt) => opt.code === rechargeAutoOption)}
                      selectDatas={rechargeAuto===0 ? [{ code: null, libelle: '---' }] : selectDatas}
                      onSelect={(val) => {                    
                        onChangeValue()
                        setRechargeAutoOption(val.code)
                      }}
                    />
                    {rechargeAuto !== 0 && rechargeAutoOption && <div className="text-xs text-red-700 font-bold">{p.t('prestations.gsm.detail.infos.infos_recharge_data_3')}</div>}
                  </div>
                }    
              </div>
            
              {canSave && <div className='flex items-center pt-1'>

                {loadingSave ? 
                  <div className="ml-2">
                    <Spinner /> 
                  </div>
                  :<>
                  <Tooltip
                    button={
                      <div className='mx-3 lg:mx-2 flex items-center justify-center'>
                        <button className='p-0.5 rounded-md bg-white/80 hover:bg-gray-200' onClick={onCancel}>
                          <XMarkIcon
                            className='h-4 w-4 text-gray-500 hover:text-gray-700' />
                        </button>
                      </div>
                    }
                    content={<div className='text-xs'>{p.t('actions.annuler')}</ div>}
                    placement='top'
                    className='flex items-center justify-center'
                  />

                  <Tooltip
                    button={
                      <div className='flex items-center justify-center'>
                        <button
                          className={clsx(
                            'p-0.5 rounded-md',
                            "bg-emerald-500 hover:bg-emerald-400"
                          )}
                          onClick={saveField}>
                          <CheckIcon
                            className='h-4 w-4 text-white' />
                        </button>
                      </div>
                    }
                    content={<div className='text-xs'>{p.t('actions.enregistrer')}</ div>}
                    placement='top'
                    className='flex items-center justify-center'
                  />
                </>}
              </div>} 
            </div>      
          </>
        }
      </div>
    </div>
  </>
}